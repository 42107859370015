import { useQuery } from '@tanstack/react-query';
import { notify } from '../../../utils/notify';
import { getAllMobileAppClusters } from '../../../api/mobileAppClientsApi';

function useGetAllMobileAppClusters() {
  return useQuery(['mobile-clusters'], getAllMobileAppClusters, {
    onError: () => notify('Error getting mobile clusters!', 'error'),
  });
}

export default useGetAllMobileAppClusters;
