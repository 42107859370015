import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changeSchoolName } from '../../../api/mobileAppClientsApi';

function useChangeSchoolName() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ selectedClusterId, cluster }) =>
      changeSchoolName(selectedClusterId, cluster),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['mobile-clusters']);
      },
    }
  );
}

export default useChangeSchoolName;
