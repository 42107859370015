import { request } from '../utils/request';

export const getAllMobileAppClusters = () => request.get('/cluster/all/mobile');

export const toggleMobileAppFlagOfCluster = (clusterId, hasMobileApp) =>
  request.put(
    `/cluster/has-mobile-app?id=${clusterId}&hasMobileApp=${hasMobileApp}`
  );

export const changeSchoolName = (clusterId, cluster) =>
  request.put(`/cluster/${clusterId}`, cluster);
