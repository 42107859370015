import React, { Fragment, PureComponent } from 'react';
import { Button, Icon } from 'antd';
import FeedbacksTable from './components/FeedbacksTable';
import AiSummaryColumn from './components/AiSummaryColumn';

class MessageFeedback extends PureComponent {
  componentDidMount() {
    const {
      data: { data },
      fetchData,
    } = this.props;
    if (!data) {
      fetchData();
    }
  }

  columns = [
    {
      title: 'Cluster',
      key: 'cluster',
      dataIndex: 'cluster',
      fixed: 'left',
      width: 150,
      render: cluster => (
        <Fragment>
          <p>ID - {cluster.id}</p>
          <p>{cluster.name}</p>
        </Fragment>
      ),
    },
    {
      title: 'Hub',
      key: 'hub',
      dataIndex: 'hub',
      width: 150,
      fixed: 'left',
      render: hub => (
        <Fragment>
          <p>ID - {hub.id}</p>
          <p>{hub.name}</p>
        </Fragment>
      ),
    },
    {
      title: 'From User',
      key: 'from_user',
      dataIndex: 'fromUser',
      width: 100,
      render: fromUser => (
        <span>
          {fromUser.firstName} {fromUser.lastName}
        </span>
      ),
    },
    {
      title: 'To User',
      key: 'to_user',
      dataIndex: 'toUser',
      width: 100,
      render: toUser => (
        <span>
          {toUser.firstName} {toUser.lastName}
        </span>
      ),
    },
    {
      title: 'Feedback',
      key: 'feedback',
      dataIndex: 'text',
      width: 200,
      render: text => (
        <p
          style={{
            maxHeight: 150,
            overflowY: 'auto',
            textAlign: 'justify',
            padding: '10px',
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: 'Feedback type',
      key: 'reviewType',
      dataIndex: 'reviewType',
      width: 200,
      render: text => (
        <p
          style={{
            maxHeight: 150,
            overflowY: 'auto',
            textAlign: 'justify',
            padding: '10px',
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: 'AI Meeting Notes',
      key: 'ai_meeting_summary',
      dataIndex: 'aiMeetingSummary',
      width: 200,
      render: (_, record) => (
        <AiSummaryColumn
          setSelectedAiMeetingSummary={this.props.setSelectedAiMeetingSummary}
          aIMeetingSummary={record.aIMeetingSummary}
        />
      ),
    },
    {
      title: 'Consent',
      key: 'consentGiven',
      dataIndex: 'consentGiven',
      width: 100,
      render: consentGiven => {
        if (consentGiven) return <span>True</span>;
        if (consentGiven === null) {
          return (
            <span
              style={{
                display: 'inline-block',
                textAlign: 'center',
                width: '100%',
              }}
            >
              -
            </span>
          );
        }
        return <span>False</span>;
      },
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating',
      width: 100,
      render: rating => (rating ? <span>{rating}/5</span> : null),
    },
    {
      title: 'Endorsements',
      key: 'endorsements',
      dataIndex: 'endorsements',
      width: 150,
      render: endorsements => {
        if (
          !endorsements ||
          !Array.isArray(endorsements) ||
          !endorsements.length
        ) {
          return null;
        }
        return (
          <>
            {endorsements.map(x => (
              <div
                style={{
                  wordBreak: 'keep-all',
                  whiteSpace: 'nowrap',
                  lineHeight: '24px',
                  margin: '4px 0',
                }}
              >
                {x.label}
                <br />
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 100,
      render: createdAt => <span>{createdAt}</span>,
    },
    {
      title: 'Open Hub',
      key: 'action',
      dataIndex: 'hub',
      width: 50,
      fixed: 'right',
      render: hub => (
        <Button type="primary" onClick={() => this.props.openHub(hub.id)}>
          <Icon type="login" />
        </Button>
      ),
    },
  ];

  paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '15', '25', '50'],
  };

  handlePaginationChange = (page, pageSize) => {
    const { setPage, setPageSize, fetchData } = this.props;
    setPage(page);
    setPageSize(pageSize);
    fetchData({ limit: pageSize, page });
  };

  handleTableChange = ({ current: page, pageSize }) => {
    this.handlePaginationChange(page, pageSize);
  };

  render() {
    const {
      data: { data, current, limit, total, loading },
    } = this.props;
    const pagination = {
      ...this.paginationOptions,
      current,
      pageSize: limit,
      total,
      showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    };

    return (
      <FeedbacksTable
        data={data}
        loading={loading}
        columns={this.columns}
        pagination={pagination}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default MessageFeedback;
