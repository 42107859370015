import { put, call } from 'redux-saga/effects';
import moment from 'moment';
import { get } from 'lodash';

import {
  messsageFeedbacksLoading,
  fetchMessageFeedbacksSuccess,
  mentorshipFeedbacksLoading,
  fetchMentorshipFeedbacksSuccess,
} from '../actions';

import { POPUP_ALERT } from '../../../actions/alert';

import {
  getMessageFeedbackAPI,
  getMentorshipFeedbackAPI,
  openHubAPI,
} from '../../../api';

export function* fetchMessageFeedbacksSaga({ data }) {
  try {
    yield put(messsageFeedbacksLoading(true));
    let response = null;
    if (data) {
      const res = yield call(getMessageFeedbackAPI, data);
      if (res.data) {
        response = res.data;
      }
    } else {
      const res = yield call(getMessageFeedbackAPI, { limit: 25, page: 1 });
      if (res.data) {
        response = res.data;
      }
    }
    if (response) {
      response.feedbacks = response.feedbacks.map(feedback => {
        const {
          id,
          fromUser,
          cluster,
          hub,
          toUser,
          rating,
          text,
          createdAt,
          consentGiven,
          endorsements,
          aIMeetingSummary,
          reviewType,
        } = feedback;
        return {
          key: id,
          cluster,
          consentGiven,
          hub: { id: hub.id, name: hub.name },
          fromUser: {
            id: fromUser.id,
            firstName: fromUser.firstName,
            lastName: fromUser.lastName,
          },
          toUser: {
            id: toUser.id,
            firstName: toUser.firstName,
            lastName: toUser.lastName,
          },
          rating,
          text,
          createdAt: `${moment(createdAt).format('MM/DD/YY')}`,
          endorsements,
          aIMeetingSummary,
          reviewType,
        };
      });
      yield put(fetchMessageFeedbacksSuccess(response));
    }
    yield put(messsageFeedbacksLoading(false));
  } catch (e) {
    yield put(messsageFeedbacksLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Loading Message Feedbacks' },
    });
  }
}

export function* fetchMentorshipFeedbacksSaga({ data }) {
  try {
    yield put(mentorshipFeedbacksLoading(true));
    let response = null;
    if (data) {
      const res = yield call(getMentorshipFeedbackAPI, data);
      if (res.data) {
        response = res.data;
      }
    } else {
      const res = yield call(getMentorshipFeedbackAPI, { limit: 25, page: 1 });
      if (res.data) {
        response = res.data;
      }
    }
    if (response) {
      response.feedbacks = response.feedbacks.map(feedback => {
        const {
          id,
          cluster,
          hub,
          user,
          reviewedUser,
          feedbackObject,
          rating,
          acceptedDate,
          endDate,
          createdAt,
          consentGiven,
          endorsements,
          aIMeetingSummary,
        } = feedback;
        return {
          key: id,
          cluster,
          consentGiven,
          hub: { id: hub.id, name: hub.name },
          fromUser: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          toUser: {
            id: reviewedUser.id,
            firstName: reviewedUser.firstName,
            lastName: reviewedUser.lastName,
          },
          feedbackObject,
          rating,
          startEndDate: `${moment(acceptedDate).format('MM/DD/YY')} - ${moment(
            endDate
          ).format('MM/DD/YY')}`,
          createdAt: `${moment(createdAt).format('MM/DD/YY')}`,
          endorsements,
          aIMeetingSummary,
        };
      });
      yield put(fetchMentorshipFeedbacksSuccess(response));
    }
    yield put(mentorshipFeedbacksLoading(false));
  } catch (e) {
    yield put(mentorshipFeedbacksLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Loading Mentorship Feedbacks' },
    });
  }
}

export function* openHubFromFeedbacksSaga({ data }) {
  try {
    const res = yield call(openHubAPI, {
      hub: data,
      reason: 'From Feedback Page',
    });
    if (res.data.link) {
      window.open(`https://${res.data.link}`, '_blank');
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Logging in to hub' },
      });
    }
  } catch (e) {
    let errMsg = 'Error Logging in to hub';
    if (get(e, 'response.data.error')) {
      errMsg = get(e, 'response.data.error');
    }
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: errMsg },
    });
  }
}
